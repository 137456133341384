export const dojos = [
  {
    name: "Shin Dojo Aikido",
    branch_off: "Shin Dojo",
    days: ["Martes", "Jueves"],
    hours: "10:00 a 11:30 & 19:00 a 20:30",
    address: "Av. Agraciada 2522",
    gmap_src:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1714.7620617917712!2d-56.19423922383902!3d-34.886810506683744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f80181d83fc97%3A0x193b5148a6a0b1cc!2sFANCAP!5e0!3m2!1ses-419!2suy!4v1654911323603!5m2!1ses-419!2suy",
    phone: "091461534",
    phone_other: "",
    sensei: ["Marcos Sosa"],
    image: "../../img/logo_iwama_ryu.png",
  },
  {
    name: "Uruguay Aiki Shurendojo",
    branch_off: "La Blanqueada",
    days: ["Lunes", "Miércoles"],
    hours: "20:30 a 22:30",
    address: "Jaime Cibils 2865a",
    gmap_src:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6545.837527811679!2d-56.162121695766366!3d-34.8833861864459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f80f975a2b16d%3A0xeb3e4252e46966e6!2sURUGUAY%20AIKI%20SHUREN%20DOJO!5e0!3m2!1ses-419!2suy!4v1654909702367!5m2!1ses-419!2suy",
    phone: "099193526",
    phone_other: "098345951",
    sensei: ["Aldo Villagra", "Marcello Scarpa"],
    image: "../../img/logo_iwama_ryu.png",
  },
  {
    name: "Uruguay Aiki Shurendojo",
    branch_off: "Yamato Dojo",
    days: ["Martes", "Jueves"],
    hours: "19:00 a 20:30",
    address: "Pasaje Claudio Garcia 970",
    gmap_src:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1636.6560494631021!2d-56.20606558255615!3d-34.873508399999984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f7f40ad052e79%3A0x2c174a8eccfb931a!2sYamato%20Dojo%20-%20Uruguay%20Budokan!5e0!3m2!1ses-419!2suy!4v1654910671050!5m2!1ses-419!2suy",
    phone: "099507411",
    phone_other: "",
    sensei: ["Andrés Camargo"],
    image: "../../img/logo_iwama_ryu.png",
  },
  {
    name: "Uruguay Aiki Shurendojo",
    branch_off: "Asociación Japonesa en el Uruguay",
    days: ["Jueves"],
    hours: "19:30 a 21:30",
    address: "Pl. Vidiella 5632",
    gmap_src:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.013513280879!2d-56.223802148704756!3d-34.805601880311606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a1d4a3733e13c1%3A0x9d8775041b01dce0!2sASOCIACI%C3%93N%20JAPONESA%20DEL%20URUGUAY%20(AJU)!5e0!3m2!1ses-419!2suy!4v1654911068020!5m2!1ses-419!2suy",
    phone: "097212971",
    phone_other: "",
    sensei: ["Walter Moyano"],
    image: "../../img/logo_iwama_ryu.png",
  },
  {
    name: "Uruguay Aiki Shurendojo",
    branch_off: "Colón",
    days: ["Martes", "Viernes"],
    hours: "19:30 a 21:30",
    address: "",
    gmap_src: "",
    phone: "099290942",
    phone_other: "",
    sensei: ["Walter Moyano"],
    image: "../../img/logo_iwama_ryu.png",
  },
];
